<template>
  <vca-card class="shadowed" style="max-width: fit-content">
    <div class="vca-center preview"><img height="400" id="preview" /></div>
    <input
      @change="fileChange"
      :accept="allowedFiles"
      type="file"
      id="avatarFile"
      class="vca-file-input"
      name="avatar"
    />
    <div>
      <button v-if="filename != ''" class="vca-button" @click="upload()">
        {{ $t("button.upload") }}
      </button>
    </div>
  </vca-card>
</template>
<script>
export default {
  name: "ReceiptUpload",
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      filename: "",
      file: null,
      allowedFiles: ".jpg, .jpeg, .png",
    };
  },
  methods: {
    dataURItoBlob(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);
      // separate out the mime component
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], { type: mimeString });
    },
    upload() {
      var formData = new FormData();
      var preview = document.querySelector("#preview");
      var blob = this.dataURItoBlob(preview.src);
      formData.append("body", JSON.stringify({ deposit_id: this.id }));
      formData.append("image", new File([blob], this.filename, blob));
      this.$store
        .dispatch({
          type: "deposits/uploadReceipt",
          data: formData,
        })
        .then(() => {
          this.$emit("updated");
        });
      this.resetFile();
    },
    fileChange(event) {
      this.file = null;
      var fileData = event.target.files[0];

      var lastDot = fileData.name.lastIndexOf(".");
      var ext = fileData.name.substring(lastDot + 1).toLowerCase();

      var allowed = this.allowedFiles.replace(/\./g, "").split(", ");
      var imageType = /^image\//;

      if (!allowed.includes(ext) || !imageType.test(fileData.type)) {
        this.notification({
          title: "messages.title.warn",
          body: this.$t("messages.error.wrong_extension", {
            0: this.allowedFiles,
          }),
          type: "warn",
        });
        this.resetFile();
        return;
      }
      var reader = new FileReader();
      reader.onload = function (e) {
        var img = document.createElement("img");
        img.onload = function () {
          var MAX_WIDTH = 3000;
          var MAX_HEIGHT = 3000;

          var width = img.width;
          var height = img.height;
          // Change the resizing logic
          if (width > height) {
            if (width > MAX_WIDTH) {
              height = height * (MAX_WIDTH / width);
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width = width * (MAX_HEIGHT / height);
              height = MAX_HEIGHT;
            }
          }

          // Dynamically create a canvas element
          var canvas = document.createElement("canvas");

          // var canvas = document.getElementById("canvas");
          var ctx = canvas.getContext("2d");
          canvas.width = width;
          canvas.height = height;
          // Actual resizing
          ctx.drawImage(img, 0, 0, width, height);

          // Show resized image in preview element
          var dataurl = canvas.toDataURL(fileData.type);
          document.getElementById("preview").src = dataurl;
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(fileData);
      this.filename = fileData.name;
    },
    resetFile() {
      var imagefile = document.querySelector("#avatarFile");
      imagefile.value = "";
      var preview = document.querySelector("#preview");
      preview.src = "";
      this.filename = "";
    },
  },
};
</script>
