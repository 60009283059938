<template>
  <vca-card>
    <h2>{{ $t("deposit.add.header") }}</h2>
    <DepositAdd @submit="submit()" @close="close" />
    <vca-popup
      v-if="submitView"
      :show="submitView"
      title="Popup"
      @close="close"
    >
      <DepositSubmit ref="donation_submit" @close="close" />
    </vca-popup>
  </vca-card>
</template>

<script>
import DepositSubmit from "../../components/finance/deposits/DepositSubmit.vue";
import DepositAdd from "@/components/finance/deposits/DepositAdd";
import { mapGetters } from "vuex";
export default {
  name: "DepositsAdd",
  components: { DepositAdd, DepositSubmit },
  created() {
    this.$store.commit("takings/list", null);
    this.$store.dispatch({ type: "crews/public" });

    let state = { status_open: true };
    if (!this.hasSystemPermission()) {
      this.create.crew_id = this.user.crew.crew_id;
      this.$store.commit("deposits/reset_create", this.user.crew.crew_id);
      state.crew_id = this.user.crew.crew_id;
    }
    this.$store.commit("takings/pg/route", "finances/taking");
    this.$store.commit("takings/pg/context", "takings/pg");
    this.$store.dispatch({
      type: "takings/pg/request",
      data: { initial: true },
    });
  },
  computed: {
    create: {
      set(value) {
        this.$store.commit("deposits/create", value);
      },
      get() {
        return this.$store.state.deposits.create;
      },
    },
    ...mapGetters({
      user: "user/current",
      hasSystemPermission: "user/roles/hasSystemPermission",
      current: "deposits/current",
    }),
  },
  data() {
    return {
      submitView: false,
    };
  },
  methods: {
    submit() {
      this.submitView = true;
    },
    close() {
      this.$store.commit("deposits/reset_create", this.user.crew.crew_id);
      this.$store.commit("deposits/reset_current");
      this.$store.commit("deposits/reset_update");
      this.$store.dispatch({
        type: "takings/pg/request",
        data: { initial: true },
      });
      this.submitView = false;
    },
  },
};
</script>
