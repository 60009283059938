<template>
  <vca-row>
    <div>
      <vca-row>
        <vca-column>
          <vca-field :label="$t('taking.filter.search.header')">
            <h3>{{ $t("taking.filter.search.label.name") }}</h3>
            <vca-input
              v-model="filter.search"
              :label="$t('taking.filter.search.label.name')"
              :placeholder="$t('taking.filter.search.placeholder.name')"
            >
            </vca-input>
            <h3>{{ $t("taking.filter.search.label.artist") }}</h3>
            <vca-input
              v-model="filter.artist_name"
              :label="$t('taking.filter.search.label.artist')"
              :placeholder="$t('taking.filter.search.placeholder.artist')"
            >
            </vca-input>
          </vca-field>
          <vca-field
            v-if="hasSystemPermission()"
            :label="$t('taking.filter.crew.header')"
          >
            <CrewFilterSelection v-model="filter.crew_id" />
          </vca-field>
        </vca-column>
        <vca-column>
          <vca-field :label="$t('taking.filter.status.header')">
            <vca-row>
              <vca-checkbox v-model="filter.status_open" id="open">
                {{ $t("taking.filter.status.open") }}
              </vca-checkbox>
              <vca-checkbox v-model="filter.status_wait" id="wait">
                {{ $t("taking.filter.status.wait") }}
              </vca-checkbox>
            </vca-row>
            <vca-row>
              <vca-checkbox v-model="filter.status_confirmed" id="confirmed">
                {{ $t("taking.filter.status.confirmed") }}
              </vca-checkbox>
              <vca-checkbox v-model="filter.status_none" id="none">
                {{ $t("taking.filter.status.none") }}
              </vca-checkbox>
            </vca-row>
            <vca-row>
              <vca-checkbox v-model="filter.status_no_income" id="no_income">
                {{ $t("taking.filter.status.no_income") }}
              </vca-checkbox>
            </vca-row>
          </vca-field>
          <vca-field :label="$t('taking.filter.type_of_event.header')">
            <vca-row>
              <vca-checkbox v-model="filter.type_of_event" id="concert">
                {{ $t("taking.filter.type_of_event.concert") }}
              </vca-checkbox>
              <vca-checkbox v-model="filter.type_of_event" id="festival">
                {{ $t("taking.filter.type_of_event.festival") }}
              </vca-checkbox>
            </vca-row>
            <vca-row>
              <vca-checkbox v-model="filter.type_of_event" id="stadium">
                {{ $t("taking.filter.type_of_event.stadium") }}
              </vca-checkbox>
              <vca-checkbox v-model="filter.type_of_event" id="network">
                {{ $t("taking.filter.type_of_event.network") }}
              </vca-checkbox>
            </vca-row>
            <vca-row>
              <vca-checkbox v-model="filter.type_of_event" id="other">
                {{ $t("taking.filter.type_of_event.other") }}
              </vca-checkbox>
            </vca-row>
          </vca-field>
        </vca-column>
      </vca-row>
      <vca-row>
        <vca-field :label="$t('taking.filter.date.headline')">
          <h3>{{ $t("taking.filter.date.event_end_from") }}</h3>
          <vca-row>
            <vca-input-date
              ref="event_end_from"
              v-model="event_end_from"
              :default="Date.now() / 1000"
              :language="this.$i18n.locale"
              :placeholder="$t('taking.filter.date.placeholder')"
            />
            <div
              v-if="filter.event_end_from"
              class="small-cancel-button input-cancel-button"
            >
              <vca-cancel-button @click="resetFrom()" />
            </div>
          </vca-row>
          <h3>{{ $t("taking.filter.date.event_end_to") }}</h3>
          <vca-row>
            <vca-input-date
              ref="event_end_to"
              v-model="event_end_to"
              :default="Date.now() / 1000"
              :language="this.$i18n.locale"
              :placeholder="$t('taking.filter.date.placeholder')"
            />
            <div
              v-if="filter.event_end_to"
              class="small-cancel-button input-cancel-button"
            >
              <vca-cancel-button @click="resetTo()" />
            </div>
          </vca-row>
        </vca-field>
      </vca-row>
      <vca-row>
        <div class="vca-center">
          <vca-cancel-button
            @click="reset"
            :placeholder="$t('table.filter.reset')"
          />
        </div>
      </vca-row>
    </div>
  </vca-row>
</template>
<script>
import { mapGetters } from "vuex";
import CrewFilterSelection from "../../../filter/CrewFilterSelection.vue";
export default {
  name: "TakingFilter",
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      select: [],
    };
  },
  computed: {
    filter: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      },
    },
    ...mapGetters({
      hasSystemPermission: "user/roles/hasSystemPermission",
    }),
    event_end_to: {
      get() {
        return this.filter.event_end_to;
      },
      set(value) {
        let newValue = this.filter;
        if (value === undefined) {
          newValue.event_end_to = value;
        } else {
          let date = new Date(this.filter.event_end_to * 1000);
          let newDate = new Date(value * 1000);
          date.setFullYear(
            newDate.getFullYear(),
            newDate.getMonth(),
            newDate.getDate()
          );
          newValue.event_end_to = date.getTime() / 1000 + 86000;
        }
        this.$emit("input", newValue);
      },
    },
    event_end_from: {
      get() {
        return this.filter.event_end_from;
      },
      set(value) {
        let newValue = this.filter;
        if (value === undefined) {
          newValue.event_end_from = value;
        } else {
          let date = new Date(this.filter.event_end_from * 1000);
          let newDate = new Date(value * 1000);
          date.setFullYear(
            newDate.getFullYear(),
            newDate.getMonth(),
            newDate.getDate()
          );
          newValue.event_end_from = date.getTime() / 1000;
        }
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    reset() {
      this.filter_all_roles = false;
      this.$emit("reset");
    },
    resetFrom() {
      this.event_end_from = undefined;
    },
    resetTo() {
      this.event_end_to = undefined;
    },
  },
  components: { CrewFilterSelection },
};
</script>
